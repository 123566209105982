import React, {useContext, useState, useEffect} from 'react';
import Dashview from 'paths/admin/dashboard/Dashview';
import axios from 'axios';
import {AppContext} from 'AppContext';
import {useParams} from "react-router-dom";


const Content = (props) => {
    // Need the account id from the URL parameters:
    const params = useParams();
    // Need the user's identity and global application config:
    const { config } = useContext(AppContext);

    const [account, set_account] = useState(null);
    const [pricing, set_pricing] = useState([]);
    const [addresses, set_addresses] = useState([
        {
            "name": "",
            "org": "Zhao Group",
            "address_1": "5 Barnwell Lane",
            "address_2": "",
            "city": "Stony Brook",
            "state": "NY",
            "zip": "11790"
        }, 
        {
            "name": "Elizabeth Zhao",
            "org": "",
            "address_1": "456 Putnam Ave",
            "address_2": "Unit 1",
            "city": "Cambridge",
            "state": "MA",
            "zip": "02139"
        }, 
        {
            "name": "Attn: Julius Sun",
            "org": "Z Corp",
            "address_1": "8 Centre of the Universe not to mention the Entire Cosmos",
            "address_2": "Apt 1",
            "city": "Boston",
            "state": "MA",
            "zip": "02119"
        }
    ]);
    const [selected_address, set_selected_address] = useState(0);

    // Retrieve relevant records for this account:
    useEffect(() => {
        const action = async () => {
            const account_api_response = await axios.get(config.api.base + '/admin/accounts/' + params.id);
            set_account(account_api_response.data[0]);
            const pricing_api_response = await axios.get(config.api.base + '/pricing/tables');
            set_pricing(pricing_api_response.data);
            console.log(pricing_api_response.data);
        }
        action();
    }, [config.api.base, params.id])

    function onChangeFirstName(args) {
        account.first_name = args.target.value;
        set_account({...account})
    }

    function onChangeLastName(args) {
        account.last_name = args.target.value;
        set_account({...account})
    }

    function onChangeCompany(args) {
        account.company = args.target.value;
        set_account({ ...account})
    }

    function onChangeEmail(args) {
        account.email = args.target.value;
        set_account({...account})
    }

    function onChangePhone(args) {
        account.phone = args.target.value;
        set_account({...account})
    }

    function onChangePriceTable(args) {
        account.pricing_id = args.target.value;
        set_account({ ...account})
    }

    function onChangeAddrTable(args) {
        set_selected_address(args.target.value);
    }

    function onChangeAddrInfo(addr, field) {
        return (args) => {
            addr[selected_address][field] = args.target.value;
            set_addresses([...addr]);
        }
    }

    function onAddNewAddr(args) {
        const addressesLength = addresses.length;
        set_addresses([...addresses, {
            "name": "",
            "org": "",
            "address_1": "",
            "address_2": "",
            "city": "",
            "state": "",
            "zip": ""
        }])
        set_selected_address(addressesLength);
    }

    function onSubmit(args) {
        args.preventDefault();
        const action = async () => {
            try {
                const response = await axios.put(config.api.base + '/admin/accounts/' + params.id, account);
                console.log(response);
            } catch (x) {

            }
        }
        action();
    }

    if(account === null) {
        return null;
    }

    return(
        <div className={"container sm:w-4/5 mx-auto mt-8"}>
            <div className="w-full space-y-8">
                <div>
                    <h2 className={"mt-6 text-3xl font-extrabold text-gray-900"}>
                        Edit Account:&nbsp;
                        <span className={"font-normal text-3xl text-gray-500"}>
                            {params.id}
                        </span>
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                    <div className={"flex"}>
                    <div className={"w-2/4 border-r-2 pr-4 space-y-4 border-dashed"}>
                        <div className={"w-full"}>
                            <h3 className="text-xl font-semibold text-gray-900">User Information</h3>
                        </div>
                        <div className={"flex space-x-6"}>
                            <div className={"w-full"}>
                                <label htmlFor="first-name">First name</label>
                                <input id="first-name" name="firstName" error={"true"}  type="text" autoComplete="given-name"
                                    value={account.first_name} required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="First name"
                                    onChange={onChangeFirstName}
                                />
                            </div>
                            <div className={"w-full"}>
                                <label htmlFor="last-name">Last name</label>
                                <input id="last-name" name="lastName" type="text" autoComplete="family-name" required
                                    value={account.last_name}
                                    onChange={onChangeLastName}
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Last name"/>
                            </div>
                        </div>
                        <div>
                            <label htmlFor="company">Company</label>
                            <input id="company" name="company" type="text" autoComplete="company"
                                required
                                value={account.company}
                                onChange={onChangeCompany}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Company"/>
                        </div>
                        <div>
                            <label htmlFor="email">Email address</label>
                            <input id="email" name="email" type="email" autoComplete="email"
                                required
                                value={account.email}
                                onChange={onChangeEmail}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Email"/>
                        </div>
                        <div>
                            <label htmlFor="phone">Phone number</label>
                            <input id="phone" name="email" type="text" autoComplete="phone"
                                required
                                value={account.phone}
                                onChange={onChangePhone}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Phone number"/>
                        </div>
                        <div className={"w-full"}>
                            <div>
                                <label htmlFor="price_table" className="block text-sm font-medium text-gray-700">
                                    Pricing Table:
                                </label>
                                <select
                                    id="price_table"
                                    name="price_table"
                                    autoComplete="price_table"
                                    value={account.pricing_id}
                                    onChange={onChangePriceTable}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    {pricing.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={" w-2/4 space-y-4 pl-4"}>
                        <div className={"w-full"}>
                            <h3 className="text-xl font-semibold text-gray-900">Address Information</h3>
                        </div>
                        <div className={"w-full"}>
                            <label htmlFor="addr_table" className="block text-sm font-medium text-gray-700">Select Address</label>
                            <div className="flex flex-row">
                                <select
                                    id="addr_table"
                                    name="addr_table"
                                    autoComplete="addr_table"
                                    value={selected_address}
                                    onChange={onChangeAddrTable}
                                    className="mt-1 block w-full py-2 px-3 overflow-ellipsis border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    {addresses.map((item, index) => {
                                        const state_zip = item.state + " " + item.zip;
                                        let address_string = [item.name, item.org, item.address_1, item.address_2, item.city, state_zip].filter(Boolean).join(", ");
                                        if (address_string.replaceAll(",", "").trim().length === 0) address_string = "New Address";
                                        return <option key={index} value={index}>{address_string}</option>
                                    })}
                                </select>
                                <div>
                                    <button className="standardButton ml-2 whitespace-nowrap" onClick={onAddNewAddr}>
                                        Add Address
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={"w-full"}>
                            <label>Recipient Name 
                                <input className="standardInput" type="text" value={addresses[selected_address].name} onChange={onChangeAddrInfo(addresses, "name")} />
                            </label>
                        </div>
                        <div className={"w-full"}>
                            <label>Organization 
                                <input className="standardInput" type="text" value={addresses[selected_address].org} onChange={onChangeAddrInfo(addresses, "org")} />
                            </label>
                        </div>
                        <div className={"w-full"}>
                            <label>Address 1<span className="text-red-600"> *</span>
                                <input className="standardInput" type="text" value={addresses[selected_address].address_1} required onChange={onChangeAddrInfo(addresses, "address_1")} />
                            </label>
                        </div>
                        <div className={"w-full"}>
                            <label>Address 2
                                <input className="standardInput" type="text" value={addresses[selected_address].address_2} onChange={onChangeAddrInfo(addresses, "address_2")} />
                            </label>
                        </div>
                        <div className={"flex flex-row justify-start items-start w-full space-x-8"}>
                            <div className="w-full">
                                <label>City<span className="text-red-600"> *</span>
                                    <input className="standardInput" type="text" value={addresses[selected_address].city} required onChange={onChangeAddrInfo(addresses, "city")} />
                                </label>
                            </div>
                            <div className="w-full">
                                <label>State<span className="text-red-600"> *</span>
                                    <input className="standardInput" type="text" value={addresses[selected_address].state} required onChange={onChangeAddrInfo(addresses, "state")} />
                                </label>
                            </div>
                            <div className="w-full">
                                <label>Zip Code<span className="text-red-600"> *</span>
                                    <input className="standardInput" type="text" value={addresses[selected_address].zip} required onChange={onChangeAddrInfo(addresses, "zip")} />
                                </label>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className={"w-2/4"}>
                        <button type={"submit"} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            SAVE
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

const Editor = (props) => {
    return (<Dashview {...props}><Content {...props}/></Dashview>);
}

export default Editor;